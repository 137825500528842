import { ErrorBoundary } from '@sentry/react';
import { createElement } from 'react';
import { createRoot } from 'react-dom/client';
import {
  ReactWebComponentWrapper,
} from '@/components/ReactWebComponent/modules/ReactWebComponentWrapper/ReactWebComponentWrapper';
import { convertHTMLAttributesToReactProps } from '@/utils/common';
import { styles } from './styles';
import type { ReactElement } from 'react';
import type { Root } from 'react-dom/client';

export class ReactWebComponent {
  renderComponent: (attrs: Record<string, string>) => ReactElement;
  name: string;
  attributesToObserve: string[];

  constructor(
    name: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    renderComponent: (attrs: Record<string, any>) => ReactElement,
    attributesToObserve: string[] = [],
  ) {
    this.renderComponent = renderComponent;
    this.name = name;
    this.attributesToObserve = attributesToObserve;
    this.mount();
  }

  private mount() {
    const { attributesToObserve, renderComponent } = this;

    const reactWebComponentPrototype = class extends HTMLElement {
      attrs: Record<string, string>;
      root: Root;

      constructor() {
        super();
        const mountPoint = document.createElement('span');
        this.root = createRoot(mountPoint);
        this.attachShadow({ mode: 'open' }).appendChild(mountPoint);
      }

      static get observedAttributes() {
        return [...attributesToObserve, 'angular-hydration-check'];
      }

      create(attrs: { [key: string]: string } & { angularHydrationCheck?: string | true }) {
        // angular renders html templates before hydration, so web component
        // may be mounted before angular `{{expressions}}` are evaluated.
        // to fix that, we add `angular-hydration-check="{{true}}"` attribute.
        // it's string initially, and becomes boolean after angular hydration.
        if (typeof attrs.angularHydrationCheck === 'string') {
          return;
        }

        this.root.render(
          <ErrorBoundary>
            <style>{styles}</style>
            {
              createElement(ReactWebComponentWrapper, {
                inner: createElement(renderComponent, attrs),
              })
            }
          </ErrorBoundary>,
        );
      }

      createPropsFromAttributes() {
        return convertHTMLAttributesToReactProps(this);
      }

      attributeChangedCallback() {
        this.create(this.createPropsFromAttributes());
      }

      connectedCallback() {
        this.create(this.createPropsFromAttributes());
      }
    };

    customElements.define(this.name, reactWebComponentPrototype);
  }
}
